// Init Sliders
(function($) {

	// device window variables
	var $window = $(window);
	var $windowsize = $window.width();

	var mainPanelHeight = $('.main-panel').height();
	// Some getters (used for development)
	// var preMenuHeight = $('.pre-menu').height();
	// var menuWithLogoPadding = 40;
	// var menuWithLogoHeight = $('.menuwithlogo').height() + menuWithLogoPadding;
	// var heightWithoutPreMenu = mainPanelHeight - preMenuHeight;
	// var heightWithoutPreMenuAndLogoMenu =  preMenuHeight + menuWithLogoHeight;

	// calculate the current height of the panel and then display the selected element to the 100vh height of the screen minus the panel height
	$('#hero-slider').css('height', '100vh').css('height', '-=' + mainPanelHeight);

	/**
	* Animate and orchestrate hero slider headers and side image
	* This function is called inside the heroSlider swiper on init and other callbacks
	* @return {String} Selector
	*/
	function animationSlider(animSwicher) {
		var count = 0;
		$('.swiper-wrapper .swiper-slide .container .grid .column .header' + animSwicher)
			.transition({
				reverse: false,
				animation: 'fade left',
				duration: 1200,
				interval: 400,
				onComplete: function() {
					count++;
					if(count == 2) {
						$('.swiper-wrapper .swiper-slide .container .grid .column img')
							.transition({
							    animation: 'fade right',
							    duration: 800,
							})
						;
					}
				}
			})
		;
	}
	
	/** 
	* Init heroSlider on index.html 
	* @type {Object}
	*/
	var heroSlider = new Swiper('.swiper-container.heroSlider', {
		pagination: '.swiper-pagination',
		paginationClickable: true,
		nextButton: '.swiper-button-next',
		prevButton: '.swiper-button-prev',
		speed: 2000,
		progress: true,
		effect: 'fade',
		onInit: function(swiper) {
			animationSlider('.notanimated');
			$('.header.trans')
				.removeClass('notanimated')
				.addClass('animated')
			;
			// console.log('incepe animatia pentru primul slide');
		},
		onSlideChangeStart: function(swiper) {
		// if( $('.swiper-wrapper .swiper-slide .container .grid .column .header.notanimated').hasClass('hidden') ) {
			animationSlider('.notanimated');
		// } else {
		//     return false;
		// }
		},
		onSlideNextStart: function(swiper) {

		},
		onSlidePrevStart: function(swiper) {

		// console.log('incepe animatia pentru slide-ul anterior');
		},
		onSlideNextEnd: function(swiper) {

		// console.log('se termina slide-ul anterior');
		},
		onSlidePrevEnd: function(swiper) {

		}
	});

	/**
	* Call swiper slider for testimonialsSection on index
	* @type {Object}
	*/
	var testimonialsSlider = new Swiper('.swiper-container.testimonials', {
		pagination: '.swiper-pagination',
		paginationClickable: true,
		spaceBetween: 50,
	});

	/**
	* Use different nr of slides per view depending on device width
	* This slider is used in index.jade at projectsSlider
	* @param  {int} value -- 1 for mobile, 2 for tablet and 3 for computer
	* @return {int}
	* @type {Object}
	*/
	function slidesPerViewNr(value) {
		var projectsSlider = new Swiper('.swiper-container.projectsSlider', {
			slidesPerView: value,
			paginationClickable: true,
			spaceBetween: 30,
			loop: true
		});

		/**
		* Because of the use of custom navigation buttons, 
		* we need to call functions for changing slides separately for each button
		*/
		$('.prev-arrow').on('click', function (e) {
			e.preventDefault();
			projectsSlider.slidePrev()
		});

		$('.next-arrow').on('click', function (e) {
			e.preventDefault();
			projectsSlider.slideNext()
		});
	}

	/** Check device width across mobile, tablet and computer 
	*   and then call function with appropiate param 
	**/
	if ($windowsize < 768) {
		slidesPerViewNr(1);
	} else if ($windowsize < 992 && $windowsize > 768) {
		slidesPerViewNr(2);
	} else {
		slidesPerViewNr(3);
	}

	/**
	* Init swiper for partners section on index.html
	* @type {Object}
	*/
	// var partnersSlider = new Swiper('.swiper-container.partnersSlider', {
	// 	slidesPerView: 6,
	// 	paginationClickable: true,
	// 	spaceBetween: 30,
	// 	autoplay: 2500,
	// 	autoplayDisableOnInteraction: false,
	// 	loop: true
	// });
	// Init gallery thumbnails

	var categoryThumbnail = new Swiper('.swiper-container.categoryThumbnail', {
		slidesPerView: 4,
		spaceBetween: 10,
	});

	/**
	* Because we use custom navigation buttons, 
	* we need to call functions for changing slides separately for each button
	*/
	$('.previous-arrowThumb').on('click', function (e) {
		e.preventDefault();
		categoryThumbnail.slidePrev()
	});

	$('.next-arrowThumb').on('click', function (e) {
		e.preventDefault();
		categoryThumbnail.slideNext()
	});
	


})(jQuery);