/**
 * Declare google recaptcha variables
 */
var ReqrecaptchaCallback;
var recaptchaCallback;
/**
 * Checks DOM changes and applies actions to the DOM elements
 * @param  {String} mutationVar
 * @param  {String} querySelector [description]
 * @param  {Function} validForm   [Callback check for valid fields which applies actions]
 */
function observeMutations(mutationVar, querySelector, validForm) {
    var mutationVar = new MutationObserver(function(mutations, mutationVar){
        validForm(querySelector)
    });
    //- run the valid form observer
    mutationVar.observe(document.querySelector(querySelector),{
        'attributes': true,
    });
}
/**
 * [removes icon chidlren from selector]
 * @param  {String} selector [description]
 * @param  {String} validation [description]
 * @return {[type]}          [description]
 */
function removeBtnIcon(selector, validation) {  // remove icon children from selector
    $(selector + validation + '.validrecaptcha')
        .children('i')
        .remove()
    ;
}
/**
 * [adds actions if the form is valid]
 * @param  {String} selector [description]
 */
function validForm(selector) {
    removeBtnIcon(selector, '.validfields')  // remove icon from submit button
    $(selector + '.validfields.validrecaptcha') // add green checkmark icon to the submit button
        .removeClass('disabled')
        .addClass('labeled icon')
        .append('<i class="green checkmark icon"></i>')
    ;
}
/**
 * [adds actions if the form is invalid]
 * @param  {'String'} selector [description]
 */
function invalidForm(selector) {
    removeBtnIcon(selector, '.invalidfields')  // remove icon from submit button
    $(selector + '.invalidfields.validrecaptcha') // add red close icon to submit button
        .addClass('disabled')
        .addClass('labeled icon')
        .append('<i class="red close icon"></i>')
    ;
}

(function($) {
    var requireForValidation = [
        { element: '#name', valid: false },
        { element: '#email', valid: false },
        { element: '#subject', valid: false },
        { element: '#message', valid: false }
    ];
    var requestAQuoteValidation = [
        { element: '#reqQuoteCompanyName', valid: false },
        { element: '#reqQuoteContactPerson', valid: false },
        { element: '#reqQuotePhoneNumber', valid: false },
        { element: '#reqQuoteProjectTitle', valid: false },
        { element: '#reqQuoteMessage', valid: false }
    ];

    // check if the recaptcha box from contact form was checked
    recaptchaCallback = function() {
        $('#ContactButton').addClass('validrecaptcha');
    }
    // check if the recaptcha box from request quote modal was checked
    ReqrecaptchaCallback = function() {
        $('#ReqQuoteSubmit').addClass('validrecaptcha');
    }

    $('.wrapperColumn .tab:nth-of-type(1)').addClass('active');
    $('.swip .item:nth-of-type(1)').addClass('active');

    $('.wwd').dropdown({
        on: 'hover',
        direction: 'downward',
        debug: true,
    });

    $('.dropaccordion').accordion();

    // form validation - semantic ui
    var $contactForm = $('.ui.form.contactUsForm');
    var $reqQuoteForm = $('.ui.form.requestQuoteForm');

    /**
    * Validate form - with i18next translation plugin
    */
    var validateForm = function validateForm(i18next) {

        $contactForm
            .form({
                fields: {
                    name: {
                        identifier: 'name',
                        rules: [
                            {
                                type   : 'empty',
                                prompt : i18next.t('contact.form.validation.name')
                            }
                        ]
                    },
                    email: {
                        identifier: 'email',
                        rules: [
                            {
                                type   : 'email',
                                prompt : i18next.t('contact.form.validation.email')
                            }
                        ]
                    },

                    subject: {
                        identifier: 'subject',
                        rules: [
                            {
                                type   : 'empty',
                                prompt : i18next.t('contact.form.validation.subject')
                            }
                        ]
                    },

                    message: {
                        identifier : 'message',
                        rules: [
                            {
                                type   : 'empty',
                                prompt : i18next.t('contact.form.validation.message')
                            }
                        ]
                    }

                },
                inline      : true,
                revalidate  : false,
                on          : 'change',
                onInvalid: function() {
                    $('#ContactButton').addClass('invalidfields').removeClass('validfields')

                    var $that = $(this[0]);
                    requireForValidation.forEach(function(r) {
                        if ($that.is(r.element)) {
                            r.valid = false;
                        }
                    });
                },
                onValid: function() {
                    var $that = $(this);
                    requireForValidation.forEach(function(r) {
                        if ($that.is(r.element)) {
                            r.valid = true;
                        }
                    });
                    var formIsValid = requireForValidation.reduce(function(a, b) {
                        return a && b.valid;
                    }, true);
                    if (formIsValid) {
                        $('#ContactButton').removeClass('invalidfields').addClass('validfields');
                    }
                }
            })
        ;

        $reqQuoteForm
            .form({
                fields: {
                    reqQuoteCompanyName: {
                        identifier: 'reqQuoteCompanyName',
                        rules: [
                            {
                                type   : 'empty',
                                prompt : i18next.t('requestQuote.form.validation.companyname')
                            }
                        ]
                    },
                    reqQuoteContactPerson: {
                        identifier: 'reqQuoteContactPerson',
                        rules: [
                            {
                                type   : 'empty',
                                prompt : i18next.t('requestQuote.form.validation.contactperson')
                            }
                        ]
                    },
                    reqQuotePhoneNumber: {
                        identifier: 'reqQuotePhoneNumber',
                        rules: [
                            {
                                type   : 'number',
                                prompt : i18next.t('requestQuote.form.validation.phonenumberInteger')
                            },
                            {
                                type   : 'minLength[8]',
                                prompt : i18next.t('requestQuote.form.validation.minimumPhoneNumber')
                            },
                            {
                                type   : 'empty',
                                prompt : i18next.t('requestQuote.form.validation.phonenumber')
                            }
                        ]
                    },
                    reqQuoteProjectTitle: {
                        identifier: 'reqQuoteProjectTitle',
                        rules: [
                            {
                                type   : 'empty',
                                prompt : i18next.t('requestQuote.form.validation.projecttitle')
                            }
                        ]
                    },
                    reqQuoteMessage: {
                        identifier : 'reqQuoteMessage',
                        rules: [
                            {
                                type   : 'empty',
                                prompt : i18next.t('requestQuote.form.validation.projectdescription')
                            }
                        ]
                    }

                },
                inline      : true,
                revalidate  : false,
                on          : 'change',
                onInvalid: function() {
                    $('#ReqQuoteSubmit').addClass('invalidfields').removeClass('validfields')
                    var $that = $(this[0]);
                    requestAQuoteValidation.forEach(function(r) {
                        if ($that.is(r.element)) {
                            r.valid = false;
                        }
                    });
                },
                onValid: function() {
                    var $that = $(this);
                    requestAQuoteValidation.forEach(function(r) {
                        if ($that.is(r.element)) {
                            r.valid = true;
                        }
                    });
                    var reqformIsValid = requestAQuoteValidation.reduce(function(a, b) {
                        return a && b.valid;
                    }, true);
                    if (reqformIsValid) {
                        $('#ReqQuoteSubmit').removeClass('invalidfields').addClass('validfields');
                    }
                }
            })
        ;
    }

    // initiate translation plugin
    i18next
        .use(window.i18nextXHRBackend)
        // .use(Cache)
        .init({
            // not necessary with current code
            // lng: 'en',
            // fallbackLng: 'en',
            backend: {
                // path where resources get loaded from
                loadPath: '../locales/{{lng}}/{{ns}}.json',
                // path to post missing resources
                // addPath: './locales/{{lng}}/{{ns}}.json',
                // your backend server supports multiloading
                // /locales/resources.json?lng=de+en&ns=ns1+ns2
                allowMultiLoading: false,
                // allow cross domain requests
                crossDomain: false,
                // define a custom xhr function
                // can be used to support XDomainRequest in IE 8 and 9
                // ajax: function (url, options, callback, data) {}
            }
        }, (err, t) => {
                validateForm(i18next);
            }
        )
    ;

    // List of languages
    // !!!Note: When adding a new language:
    // a. Each language added here has to have a correspondent at locales/{newlanguage}/{translation.json}
    // b. Take a loot at the base.jade file to add new element, element class and data-lang attribute inside the dropdown module
    var languages = [
        {
            ext: 'eng',
            lcode: 'en-US'
        },
        // {
        //     ext: 'rom',
        //     lcode: 'ro-RO'
        // },
        // {
        //     ext:  'ger',
        //     lcode: 'de-DE'
        // },
        // {
        //     ext: 'fre',
        //     lcode: 'fr-FR'
        // },
        // {
        //     ext: 'rus',
        //     lcode: 'ru-RU'
        // }
    ];

    const dropLanguages = document.getElementById('testClass');
    class Language {
        constructor(languageClassToAdd, i18nextLanguage) {
            this.languageClassToAdd = languageClassToAdd;
            this.i18nextLanguage = i18nextLanguage;
        }
        ChangeLanguage() {
            const addLang = dropLanguages.getElementsByClassName(this.languageClassToAdd)[0];
            addLang.classList.add('active');
            localStorage.clear();
            localStorage.setItem(this.i18nextLanguage, '1');
            i18next.changeLanguage(this.i18nextLanguage, function(err, t) {
            // init jquery i18next before indicating translations
                i18nextJquery.init(i18next, $);
                // indicate elements to be translated
                $('body').localize();
                validateForm(i18next);
            });
        }
        ChangeUrl(page, url) {
            if (typeof (history.replaceState) != 'undefined') {
                var obj = {Page: page, Url: window.location.pathname + '?lng=' + url};
                history
                    .replaceState(
                        obj,
                        obj.Page,
                        obj.Url
                    )
                ;
            } else {
                return false;
            }
        }
    }
    // not working with just one for loop
    for (let j = 0; j < languages.length; j++) {
        let extVal = languages[j].ext;
        let lcodeVal = languages[j].lcode;
        // Change language on page load depending on url
        if(window.location.href.includes(`?lng=${lcodeVal}`)) {
            const swichLg = new Language(extVal, lcodeVal);
            swichLg.ChangeLanguage();
            swichLg.ChangeUrl(null, lcodeVal);
        }
    }
    for (let i = 0; i < languages.length; i++) {
        let extVal = languages[i].ext;
        let lcodeVal = languages[i].lcode;
        // Remember selected language for other pages inside the entire website
        if ( localStorage.getItem(lcodeVal) ) {
            const swichLg = new Language(extVal, lcodeVal);
            swichLg.ChangeLanguage();
            swichLg.ChangeUrl(null, lcodeVal);
        }
    }

    $langDropdown = $('.language'); // get semantic ui dropdown component
    $langDropdown
        .dropdown({
            on: 'click',
            debug: true,
            onChange: function(value, text, $choice) {
                var btnClass = $choice.attr('class').split(' ')[2];
                var lngChosen = $choice.data('lang');
                // Set language depending on what is selected in the languages dropdown within the top main panel
                // changeLang(btnClass, lngChosen);
                const swichLg = new Language(btnClass,lngChosen);
                swichLg.ChangeLanguage();
                swichLg.ChangeUrl(null, lngChosen);

                $contactForm.form('revalidate');
                $contactForm.form('reset');
                $contactForm.form('destroy');
            }
        })
    ;

    // fade in the page after document loads
    $('body').transition({
        animation: 'fade',
        duration: 1200,
        displayType: 'block',
        onComplete: function() {

            // $('#ContactButton.validfields.validrecaptcha').addClass('labeled icon').append('<i class="green checkmark icon"></i>');
            if($('.contactUsForm .fields .field.required').hasClass('error')){
                console.log('works')
            }

             // device window variables
            var $window = $(window);
            var $windowsize = $window.width();

            $('.swip .swiper-slide .image')
                .dimmer({
                    on: 'hover'
                })
            ;
            //detect active image
            var previous = $('.wrapperColumn .tab.active');
            //- //change image
            $('.swip .item').tab({
                onVisible: function(e) {
                    var current = $('.wrapperColumn .tab.active');
                    // hide current image and show previous
                    previous.show();
                    current.hide();
                    //hide previous image
                    previous.transition({
                        animation: 'fade left',
                        duration: 200,
                        onComplete: function() {
                            current.transition({
                                animation: 'fade right',
                                duration: 200,
                                mobileTransition: 'fly in right'
                            });
                        }
                    });
                    // remember current image for next change
                    previous = current;
                }
            });

            // open request a quote modal
            $('.reqQuoteBtn').click(function() {
                $('.ui.long.modal.requestQuote').modal({
                    transition: 'fade right',
                    duration: 600
                }).modal('show');
            });

            // check window size and use different sidemenu offset for tablet and wide screens / also remove sticky for mobile
            let checkWhatwedoSidebarOffset = (value) => {
                if ($windowsize > 767) {
                    $('.railMenu').sticky({
                        context: '.railContent',
                        offset: value
                    });
                }
            }
            if ($windowsize > 992) {
                checkWhatwedoSidebarOffset(66);
            } else {
                checkWhatwedoSidebarOffset(86);
            }

        $('#cardsOverview')
            .visibility({
                type: 'image',
                observeChanges: true,
                animation: 'fade left in',
                duration: 1800,
                onTopVisible: function() {
                    $(this).find('.division-box').transition({
                        animation: 'fade down',
                        duration: 1000,
                        interval: 200
                    })
                }
            });

            // toggle sidebar when user clicks on mobile menu items
            $('.mobileMenu a.item').click(function() {
                $('.ui.sidebar').sidebar('toggle');
            });

            // fade in the raised contact form after the page loads
            $('.ui.form').transition({
                 animation: 'fade down',
                 duration: 1000,
                 // load google map after the fade up animation of the contact form is complete
                 onComplete: function() {
                     if (window.google && google.maps) {
                        // alert('Error loading google map');
                     } else {
                        // lazy load google map
                        lazyLoadGoogleMap();
                     }
                 }
            });
            $('.toggle-form').click(function() {
                $('.ui.form').transition({
                    animation: 'fade down',
                    duration: 500
                });
                $('.toggle-form i.icon').toggleClass('up').toggleClass('down');
            });
            // send form on email throught ajax
            $contactForm
                .on('submit',
                    function (evt) {
                        // Prevent submmiting the form normally
                        evt.preventDefault();
                        if ( $(evt.currentTarget).form('is valid') ) {
                            var formData = $contactForm.serializeArray();
                            $.ajax({
                                type: 'POST',
                                url: 'assets/js/contact_sendmail.php',
                                dataType: 'json',
                                data: formData,
                                // display loader before sending the form
                                beforeSend: function() {
                                    $('.dimmer.loadDim').addClass('active');
                                },
                                // if the form has sent successfully
                                success: function(response) {

                                    if (response.success) {
                                        $('.dimmer.loadDim').removeClass('active');
                                        $('.ui.success.modal')
                                            .modal('show') // show success message
                                        ;
                                        $('.ui.error.modal')
                                            .remove()  // remove loader
                                        ;
                                        $contactForm
                                            .find('input') // find all inputs
                                            .val('') // empty input fields
                                        ;
                                        $contactForm
                                            .find('textarea') // find textarea
                                            .val('') // empty textarea
                                        ;
                                        $('#ContactButton.validfields.validrecaptcha') // find success button
                                            .addClass('disabled') // disable it
                                            .removeClass('labeled icon validfields validrecaptcha') // remove success classes
                                            .children('i') // find checkmark icon inside the button
                                            .remove() // remove checkmark icon
                                        ;
                                        grecaptcha
                                            .reset() // reset recaptcha
                                        ;
                                    }
                                    // if sending the form has failed
                                    else {
                                        $('.dimmer.loadDim').removeClass('active');
                                        // $('.ui.load.modal')
                                        //     .modal('setting', 'transition', 'fade left') // show loader
                                        //     .modal('hide') // hide the loader modal
                                        // ;
                                        $('.ui.error.modal')
                                            .modal('show'); // show error message
                                    }
                                }
                            });
                        }
                    }
                )
            ;
            // send form on email throught ajax
            $reqQuoteForm
                .on('submit',
                    function (evt) {
                        // Prevent submmiting the form
                        evt.preventDefault();
                        if ( $(evt.currentTarget).form('is valid') ) {
                            var formData = $reqQuoteForm.serializeArray();
                            $.ajax({
                                type: 'POST',
                                url: '../assets/js/contact_requestQuote.php',
                                dataType: 'json',
                                data: formData,
                                // display loader before sending the form
                                beforeSend: function() {
                                    $('.requestQuote .dimmer').addClass('active')
                                },
                                // if the form has sent successfully
                                success: function(response) {
                                    if (response.success) {
                                        $('.requestQuote .dimmer').removeClass('active')
                                        $('.ui.success.modal').modal('show')
                                        $reqQuoteForm
                                            .find('input') // find all inputs
                                            .val('') // empty input fields
                                        ;
                                        $reqQuoteForm
                                            .find('textarea') // find textarea
                                            .val('') // empty textarea
                                        ;
                                        $('#ReqQuoteSubmit.validfields.validrecaptcha') // find success button
                                            .addClass('disabled') // disable it
                                            .removeClass('labeled icon validfields validrecaptcha') // remove success classes
                                            .children('i') // find checkmark icon inside the button
                                            .remove() // remove checkmark icon
                                        ;
                                        grecaptcha
                                            .reset() // reset recaptcha
                                        ;
                                    }
                                    // if sending the form has failed
                                    else {
                                        $('.requestQuote .dimmer').removeClass('active')
                                        $('.ui.load.modal')
                                            .modal('setting', 'transition', 'fade up') // show loader
                                            .modal('hide') // hide the loader modal
                                        ;
                                        $('.ui.error.modal')
                                            .modal('show'); // show error message
                                    }
                                }
                            });
                        }
                    }
                )
            ;

            // set current year inside footer
            var d = new Date();
            var n = d.getFullYear();
            $('.currentyear').text(n);


            //- init sidebar and change icon on visible/hide
            $('.ui.sidebar.mobileMenu')
                .sidebar({
                    dimPage: false,
                    onVisible: function() {
                        $('.launch i').removeClass('sidebar');
                        $('.launch i').addClass('close white');
                    },
                    onHide: function() {
                        $('.launch i').removeClass('close white');
                        $('.launch i').addClass('sidebar');

                    }
                })
                .sidebar(
                    'attach events',
                    '.ui.launch'
                )
            ;


            if (!$('.menu-wrap').hasClass('fixed') ) {
                $('.mobileMenu').removeClass('preTop');
                $('.mobileMenu').addClass('preTopbefore');
            }

            $('.ui.sticky.wrap')
                .sticky({
                    context: '.pusher',
                    onStick: function() {
                        $('.content-wrap').addClass('hero-top');
                        $('.mobileMenu').removeClass('preTopbefore');
                        $('.mobileMenu').addClass('preTop');
                        if($windowsize > 991) {
                            $('.logo').removeClass('ns mobile-only').addClass('fade');
                            $('.ui.borderless.menu .ui.container .ui.item.text.menu.grid.computer.only').removeClass('six').addClass('seven');
                        }
                    },
                    onUnstick: function() {
                        $('.content-wrap').removeClass('hero-top');
                        $('.mobileMenu').removeClass('preTop');
                        $('.mobileMenu').addClass('preTopbefore');
                        if($windowsize > 991) {
                            $('.logo').addClass('ns mobile-only').removeClass('fade');
                            $('.ui.borderless.menu .ui.container .ui.item.text.menu.grid.computer.only').removeClass('seven').addClass('six');
                        }
                    }
                })
            ;
        }
    });
})(jQuery);

